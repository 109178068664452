<template>
  <div class="container">
    <div class="head">
      <div class="back" @click="returnToEdit">
        <i class="el-icon-arrow-left"></i>
      </div>
      <img
        class="searchIcon"
        v-show="!isFocus"
        mode="widthFix"
        src="@/assets/images/editMusic/ze-search@3x.png"
      />
      <input
        type="text"
        :placeholder="isFocus ? '请输入歌名' : '搜索音乐'"
        :style="
          isFocus ? 'text-align: left; padding-left: 6%' : 'text-align: center;'
        "
        v-model="keyWord"
        @blur="onBlur"
        @focus="onFocus"
        @input="searchSong"
        class="search"
      />
      <div class="play">
        <img
          class="icon"
          mode="widthFix"
          src="@/assets/images/editMusic/playing.png"
        />
        <div class="center">
          <div class="text">{{ selectedSong.name }}</div>
          <div class="used">使用中</div>
        </div>
        <img
          class="usedIcon"
          mode="widthFix"
          src="@/assets/images/editMusic/iconPark-check-small@3x.png"
        />
      </div>
    </div>
    <div class="select">
      <div
        v-for="(song, index) in songs"
        :key="index"
        class="song"
        @click="select(song, index)"
      >
        <span :style="{ color: playingIndex == index ? '#eb5c69' : '#000' }">{{
          song.name
        }}</span>
        <!-- <div v-if="index < 5 && searchSongsList.length == 0" class="hot">
          HOT
        </div> -->
      </div>
    </div>
    <div class="bottom">
      <div class="round">
        <img
          class="circle"
          mode="widthFix"
          src="@/assets/images/editMusic/disk.png"
        />
        <img
          class="pauseMusic"
          v-show="isPlay"
          mode="widthFix"
          src="@/assets/images/editMusic/pause.png"
          @click="pauseMusic"
        />
        <img
          class="pauseMusic"
          v-show="!isPlay"
          mode="widthFix"
          src="@/assets/images/editMusic/play.png"
          @click="playMusic"
        />
      </div>
      <div class="music">
        <div class="display">
          <div class="text">{{ playSong.name }}</div>
          <el-slider
            v-model="value"
            :step="step"
            @change="onChange"
            @input="format"
            :show-tooltip="false"
            class="slider"
          >
            <template #button>
              <div class="custom-button"></div>
            </template>
          </el-slider>
          <div class="time">{{ time }}</div>
        </div>
        <div class="button" @click="useThisSong">使用</div>
      </div>
      <div class="line"></div>
    </div>
    <audio
      ref="audio"
      :src="playingMusic"
      style="position: fixed; top: 0"
      autoplay
      loop
    ></audio>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "EditMusic",
  props: ["music"],
  data() {
    return {
      keyWord: "",
      isFocus: false,
      selectedSong: {
        name: "",
        cloudID: "",
        _openid: "",
        _id: "",
      },
      playSong: {
        name: "",
        cloudID: "",
        _openid: "",
        _id: "",
      },
      songs: [],
      searchSongsList: [],
      value: 0,
      step: 1,
      totalTime: 264, // 歌曲总时间264s, 去获取
      time: "0:00",
      // interval: Object,
      isPlay: true,
      playingMusic: "",
      limit: 25,
      page: 1,
      timeout: null,
      playingIndex: -1,
    };
  },
  mounted() {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        if(this.isPlay){
          this.pauseMusic()
        }
      } else {
        setTimeout(() => {
          this.playMusic()
        }, 1000);
      }
    });
    window.addEventListener("scroll", this.watchScroll, true);
    this.searchSongsList = this.songs;
    this.step = 100 / this.totalTime;
    console.log(123, this.music);
    this.selectedSong = {
      name: this.music.split("/").pop().slice(0, -4),
    };

    // this.selectedSong = {
    //   name: decodeURI(this.$route.query.music).split("/").pop().slice(0, -4),
    // };

    this.getMusic();
    this.$refs.audio.oncanplay = () => {
      this.totalTime = this.$refs.audio.duration;
      this.interval = setInterval(() => {
        this.value =
          (100 * this.$refs.audio.currentTime) / this.$refs.audio.duration;
        this.format(this.value);
      }, 1000);
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.watchScroll, true);
    clearInterval(this.interval);
  },
  methods: {
    onChange(value) {
      console.log("改变value");
      this.$refs.audio.currentTime = (value * this.$refs.audio.duration) / 100;
    },
    format(value) {
      let now = (value / 100) * this.totalTime;
      let min = Math.floor(now / 60);
      let second = Math.floor((now / 60 - min) * 60);
      if (second - 10 < 0) this.time = `${min}:0${second}`;
      else this.time = `${min}:${second}`;
    },
    onBlur() {
      this.isFocus = false;
    },
    onFocus() {
      this.isFocus = true;
      this.searchSongsList = [];
    },
    searchSong() {
      this.page = 1;
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = setTimeout(() => {
        this.songs = [];
        this.getMusic();
      }, 400);
    },
    select(song, index) {
      this.playingIndex = index;
      this.time = "0:00";
      this.isPlay = true;
      this.playSong = song;
      clearInterval(this.interval);
      // 改动
      this.playingMusic = song.url;
      // this.changePlayingMusic(song.cloudID);
      this.value = 0;
    },
    pauseMusic() {
      clearInterval(this.interval);
      this.$refs.audio.pause();
      this.isPlay = false;
    },
    playMusic() {
      if (this.playingMusic == "") {
        Toast("未选择音乐");
        return;
      }
      this.interval = setInterval(() => {
        this.value = this.value + this.step;
        this.onChange(this.value);
      }, 1000);
      this.$refs.audio.play();
      this.isPlay = true;
    },
    useThisSong() {
      if (this.playSong.cloudID == "") {
        Toast("未选择音乐");
        return;
      }
      this.selectedSong = this.playSong;
      this.$bus.$emit("selectSong", this.selectedSong.cloudID);
      Toast("使用成功");
      setTimeout(() => {
        this.returnToEdit();
      }, 1000);
    },
    returnToEdit() {
      this.$emit("closeEditMusic");
    },
    getMusic() {
      this.$cloud.callFunction({
        name: "router",
        data: {
          url: "getMusic",
          params: {
            searchKey: this.keyWord,
            page: this.page,
            limit: this.limit,
          },
        },
        success: (res) => {
          if (res.result.data.length == 0) {
            Toast("没有更多数据啦~");
            return;
          }
          this.songs.push(...res.result.data);
          this.$cloud
            .getTempFileURL({
              fileList: res.result.data.map((item) => item.cloudID),
            })
            .then((res1) => {
              this.songs.splice(
                -this.limit,
                this.limit,
                ...res.result.data.map((item, index) =>
                  Object.assign(item, { url: res1.fileList[index].tempFileURL })
                )
              );
            });
          // 改动
          // res.result.data.forEach((item) => {
          //   this.$cloud
          //     .getTempFileURL({
          //       fileList: [item.cloudID],
          //     })
          //     .then((res) => {
          //       res.fileList[0].tempFileURL;
          //       let urlsong = {
          //         url: res.fileList[0].tempFileURL,
          //         name: item.name,
          //         cloudID: item.cloudID,
          //       };
          //       this.songs.push(urlsong);
          //     });
          // });
        },
      });
      console.log("当前songs", this.songs);
    },
    watchScroll() {
      if (this.isWindowBottom()) {
        ++this.page;
        this.getMusic();
      }
    },
    isWindowBottom() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      return scrollTop + windowHeight >= scrollHeight;
    },
    // changePlayingMusic(fileID) {
    //   this.$cloud
    //     .getTempFileURL({
    //       fileList: [fileID],
    //     })
    //     .then((res) => {
    //       console.log(res.fileList[0].tempFileURL);
    //       this.playingMusic = res.fileList[0].tempFileURL;
    //     });
    // },
  },
  watch: {
    // music: {
    //   immediate: true,
    //   handler(value) {
    //     console.log(123, this.music);
    //     // this.selectedSong = this.music
    //     this.selectedSong = {
    //       name: value.split("/").pop().slice(0, -4),
    //     };
    //   },
    // },
    // keyWord: {
    //   handler(newValue) {
    //     this.$cloud.callFunction({
    //       name: "router",
    //       data: {
    //         url: "getMusic",
    //         params: {
    //           searchKey: newValue,
    //           page: 1,
    //           limit: 100,
    //         },
    //       },
    //       success: (res) => {
    //         this.songs = res.result.data;
    //       },
    //       fail: console.error,
    //     });
    //   },
    // },
  },
};
</script>

<style lang="scss">
.el-slider__bar {
  background-color: #ee0a24;
}
.el-slider__button {
  background-color: #ee0a24;
  border: 0;
}
.el-slider__runway {
  margin: 0;
}
</style>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  font-size: 14px;
  padding: 0;
  margin: 0;
  font-family: "PingFangSC-medium";
}
img {
  display: block;
}
.container {
  width: 100%;
  height: 100%;
  background: rgb(248, 248, 248);
}
.head {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;
  padding-left: 20px;

  .back {
    position: absolute;
    top: 5vw;
    left: 10px;
    transform: translateY(50%);

    .el-icon-arrow-left {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .searchIcon {
    position: absolute;
    width: 6%;
    top: 8vw;
    left: 64%;
  }
  .search {
    width: 90%;
    height: 44px;
    background-color: rgba(240, 240, 240, 0.58);
    border-radius: 22px;
    text-align: center;
    caret-color: #ababab;
    color: #ffababab;
    border: 0px;
    margin: 5vw 5% 0;
  }
  .play {
    position: relative;
    width: 90%;
    margin: 7% auto;
    color: #eb5c69;
    display: flex;
    .icon {
      width: 5%;
      margin-right: 3%;
    }
    .center {
      max-width: 85%;
      display: flex;
      flex: 1;
      .text {
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .used {
        display: inline-block;
        min-width: 50px;
        height: 20px;
        line-height: 20px;
        border-radius: 4px;
        background-color: rgba(235, 92, 105, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        text-align: center;
        font-family: Arial;
        margin-left: 5%;
      }
    }

    .usedIcon {
      width: 5%;
    }
  }
}
.select {
  width: 100%;
  height: 100%;
  background: #fff;
  margin-top: 8px;
  margin-bottom: 30%;
  .song {
    display: flex;
    width: 80%;
    height: 52px;
    line-height: 52px;
    margin-left: 10%;
    border-bottom: 1px solid rgba(187, 187, 187, 0.15);
    color: rgba(6, 6, 6, 1);

    span {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .hot {
      width: 28px;
      height: 14px;
      line-height: 15px;
      border-radius: 2px;
      background-color: rgba(255, 140, 150, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 10px;
      text-align: center;
      font-family: Arial;
      margin: auto 4%;
    }
  }
}
.bottom {
  position: fixed;
  width: 100%;
  height: 13%;
  background-color: #fff;
  box-shadow: 0px -7px 18px 0px rgba(215, 215, 215, 0.4);
  bottom: 0;
  .round {
    position: absolute;
    display: inline-block;
    width: 21%;
    left: 3%;
    top: -15%;
    .circle {
      width: 100%;
    }
    .pauseMusic {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 30%;
    }
  }
  .music {
    display: flex;
    width: 88%;
    height: 80px;
    margin-left: 26.5%;
    .display {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 63%;
      height: 100%;
      .text {
        font-family: "PingFangSC-regular";
        color: #eb5c69;
        margin-top: 5%;
        margin-bottom: 5%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      /deep/ .el-slider__runway {
        // width: 100%;
        height: 4px;
      }
      /deep/ .el-slider__bar {
        height: 4px;
        background-color: #eb5c69;
      }
      /deep/ .el-slider__button {
        background-color: #eb5c69;
      }
      .slider {
        width: 100%;
        vertical-align: center;
        height: 20px;
        .custom-button {
          width: 12px;
          height: 12px;
          background-color: #eb5c69;
          border-radius: 6px;
        }
      }
      .time {
        text-align: right;
        color: #ababab;
        font-size: 10px;
      }
    }
    .button {
      font-family: Arial;
      width: 15%;
      height: 28px;
      line-height: 28px;
      text-align: center;
      background-color: #eb5c69;
      color: #ffffff;
      border-radius: 4px;
      margin-left: 2.5%;
      margin-top: 8%;
    }
  }
  .line {
    position: fixed;
    width: 32.5%;
    height: 5px;
    background-color: #000;
    border-radius: 3px;
    bottom: 6px;
    left: 33.75%;
  }
}
</style>